import React from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import Form from "../../components/contact/form/form";
import Counselor from "../../components/contact/counselor/Counselor";
import "./style.scss"

const ContactPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content, featuredImage
    }
  } = props;
  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const contact = props.pageContext.acfContact;
  const form = contact.form
  const counselor = contact.counselor
  return (
    <Layout lang={lang} page="contact" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"contact"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="contact page">
              <section className="description">
                <div className="container">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                </div>
              </section>
              <section className="contact__columns">
                <div className="container">
                  <div className="contact__columns__wrapper">
                    <div className="contact__columns__item column--form">
                      <Form form={form} />
                    </div>
                    <div className="contact__columns__item column--counselor">
                      <Counselor counselor={counselor} featuredImage={featuredImage.node} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default ContactPage;

